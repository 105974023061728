import { useClassName } from '@/styles/JwVideoTeaser';
import { ClassNameProp, Component } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

import NextImage, { ImageProps as NextImageProps } from 'next/image';

export interface VideoTeaserMediaImageProps extends Omit<NextImageProps, 'src' | 'alt'> {
  variant?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'default'>;
  mediaId?: string;
  alt?: string;
}

export const VideoTeaserMediaImageComponent: Component<VideoTeaserMediaImageProps> = ({
  children,
  variant,
  size,
  colors,
  alt = '',
  mediaId,
  ...props
}) => {
  const className = useClassName('media_image', { variant, size, colors });

  return (
    <NextImage
      alt={alt}
      src={`https://cdn.jwplayer.com/v2/media/${mediaId}/poster.jpg?width=640`}
      {...mergeProps(
        {
          className,
          width: 640,
          height: 360,
          unoptimized: true,
        },
        props
      )}
    />
  );
};
